import React, { useState, useEffect } from "react";

import { rafThrottler } from "../rafThrottler";
import { BREAKPOINTS } from "./grid";

export const ScreenInfoContext = React.createContext();

export function ScreenInfoProvider({ children }) {
  const [screenSize, setScreenSize] = useState("xs");

  function setCurrentScreenSize() {
    const windowWidth = window.innerWidth;
    let screenSize = "small";

    if (windowWidth > BREAKPOINTS.xs && windowWidth <= BREAKPOINTS.md) {
      screenSize = "medium";
    }

    if (windowWidth > BREAKPOINTS.md && windowWidth <= BREAKPOINTS.lg) {
      screenSize = "large";
    }

    if (windowWidth > BREAKPOINTS.lg) {
      screenSize = "xlarge";
    }

    setScreenSize(screenSize);
  }

  const handleResize = rafThrottler(setCurrentScreenSize);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return (
    <ScreenInfoContext.Provider value={screenSize}>
      {children}
    </ScreenInfoContext.Provider>
  );
}
