import React, { forwardRef } from "react";
import styled from "styled-components";

export const BREAKPOINTS = {
  xs: 500,
  md: 800,
  lg: 1200,
};

const DEFAULT_WIDTH = 100 / 12;

const RowHolder = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent};
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const ColumnHolder = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  width: ${({ lgSpan }) => lgSpan * DEFAULT_WIDTH}%;
  padding: ${({ padding }) => padding}px;

  @media (max-width: ${BREAKPOINTS.md}px) {
    width: ${({ mdSpan }) => mdSpan * DEFAULT_WIDTH}%;
  }

  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: ${({ xsSpan }) => xsSpan * DEFAULT_WIDTH}%;
  }
`;

export const Row = forwardRef(({ children, justifyContent, ...props }, ref) => {
  return (
    <RowHolder justifyContent={justifyContent} {...props} ref={ref}>
      {children}
    </RowHolder>
  );
});

export const Column = forwardRef(
  (
    {
      children,
      xs = 12,
      md,
      lg,
      padding = 16,
      flexDirection = "column",
      justifyContent = "flex-start",
      alignItems = "flex-start",
      ...props
    },
    ref
  ) => {
    const xsSpan = xs;
    const mdSpan = md || xsSpan;
    const lgSpan = lg || mdSpan;

    return (
      <ColumnHolder
        xsSpan={xsSpan}
        mdSpan={mdSpan}
        lgSpan={lgSpan}
        padding={padding}
        flexDirection={flexDirection}
        justifyContent={justifyContent}
        alignItems={alignItems}
        ref={ref}
        {...props}
      >
        {children}
      </ColumnHolder>
    );
  }
);
