// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-portfolio-pl-js": () => import("./../src/templates/portfolio.pl.js" /* webpackChunkName: "component---src-templates-portfolio-pl-js" */),
  "component---src-templates-portfolio-en-js": () => import("./../src/templates/portfolio.en.js" /* webpackChunkName: "component---src-templates-portfolio-en-js" */),
  "component---src-templates-offer-pl-js": () => import("./../src/templates/offer.pl.js" /* webpackChunkName: "component---src-templates-offer-pl-js" */),
  "component---src-templates-offer-en-js": () => import("./../src/templates/offer.en.js" /* webpackChunkName: "component---src-templates-offer-en-js" */),
  "component---src-pages-index-en-js": () => import("./../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-pl-js": () => import("./../src/pages/index.pl.js" /* webpackChunkName: "component---src-pages-index-pl-js" */)
}

